import React, { Component } from 'react';
import {graphql} from 'gatsby'
import parse from 'html-react-parser';
import DepartmentNewsController from 'controllers/blog/departmentnews';
import Layout from 'components/layout/basic';
import LocationList from 'components/locations/list';
import Masthead from 'components/masthead/specialtybasic';
import PatientEducationController from 'controllers/blog/patienteducation';
import ProviderList from 'components/providers/list';
import SEO from 'shared/seo'
import Specialties from 'components/specialties/';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

export default class PhysiciansDepartmentsGet extends Component {
	
	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}physicians/department/get/?_join=true&uri=${this.department.uri}`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					let department = this.processData(data[0]);
					this.setState({
						status: STATUS.LOADED,
						data: department,
					})
				}
			})
			.catch(err => console.log);
	}

	constructor(props) {
		super(props);
		this.allSpecialties = (this.props.data.allPhysiciansSpecialty && this.props.data.allPhysiciansSpecialty.edges.length ? this.props.data.allPhysiciansSpecialty.edges : null);
		this.allLocations = (this.props.data.allLocationsLocation && this.props.data.allLocationsLocation.edges.length ? this.props.data.allLocationsLocation.edges : null);
		this.allCategories = (this.props.data.allPhysiciansCategory && this.props.data.allPhysiciansCategory.edges.length ? this.props.data.allPhysiciansCategory.edges : []);
		this.department = (this.props.data.allPhysiciansDepartment && this.props.data.allPhysiciansDepartment.edges.length ? this.props.data.allPhysiciansDepartment.edges[0].node : null);
		this.departmentSpecialties = [];
		let departmentID = this.department.id;
		this.allSpecialties.forEach(specialty => {
			if (specialty.node.Department.length) {
				this.departmentSpecialties.push(specialty);
			}
		});
		let initialData = this.processData((this.props.data.allPhysiciansDepartment && this.props.data.allPhysiciansDepartment.edges.length ? this.props.data.allPhysiciansDepartment.edges[0].node : null));	
		this.state = {
			status: STATUS.INITIAL,
			data: initialData,
			showHR: false,
			department: this.department,
		}
		this.liveRefresh = this.liveRefresh.bind(this);
		this.processData = this.processData.bind(this);
	}

	componentDidMount() {
		this.liveRefresh();
	}

	processData(data) {
		function removeDuplicateObjectFromArray(array, key) {
			return array.filter((obj, index, self) =>
				index === self.findIndex((el) => (
					el[key] === obj[key]
				))
			)
		}
		let newDatum = {
			name: data.name,
			description: data.description,
			sidebar: data.sidebar,
			background: data.backgroundColor,
			sidebarImage: data.sidebarImage,
			tags: data.tags,
			providers: [],
			locations: [],
			specialties: [],
		}
		if (data.Specialty && data.Specialty.length) {
			let specialties = [];
			data.Specialty.forEach(specialty => {
				let specialtyObj = {
					title: specialty.object.name,
					link: specialty.object.uri,
				};
				specialties.push(specialtyObj);
			});
			if (specialties.length) {
				specialties.sort((a, b) => {
					let fa = a.title.toLowerCase(),
						fb = b.title.toLowerCase();

					if (fa < fb) {
						return -1;
					}
					if (fa > fb) {
						return 1;
					}
					return 0;
				});
				newDatum.specialties = specialties;
			} else {
				newDatum.specialties = null;
			}
		}
		// Parse out the specialty providers
		if (this.departmentSpecialties && this.departmentSpecialties.length) {
			let providers = [];
			this.departmentSpecialties.forEach(specialty => {
				let specialtyProviders = [];
				specialty.node.Provider.forEach(provider => {
					let providerObj = {
						firstName: provider.object.firstName,
						lastName: provider.object.lastName,
						title: provider.object.abbreviations.toString(),
						category: null,
						specialty: ['Physician'],
						photo: provider.object.photo,
						link: provider.object.uri,
						phone: provider.object.directPhone,
						fax: provider.object.fax,
						id: provider.object.id,
						location: [],
					};
					if (provider.object.Location && provider.object.Location.length) {
						let locations = [];
						provider.object.Location.forEach(location => {
							if (location.object.id) {
								locations.push(location.object.id);
							}
						});
						if (locations.length) {
							providerObj.location = locations;
						}
					}
					if (provider.object.Specialty && provider.object.Specialty.length) {
						let specialties = [];
						provider.object.Specialty.forEach(specialty => {
							if (specialty.object.id) {
								if (this.allSpecialties.length) {
									this.allSpecialties.forEach(item => {
										if (item.node.id === specialty.object.id) {
											specialties.push(item.node.name);
										}
									});
								}
							}
						});
						if (specialties.length) {
							providerObj.specialty  = specialties;
						} else {
							providerObj.specialty  = null;
						}
					}
					if (provider.object.Category && provider.object.Category.length) {
						let categories = [];
						provider.object.Category.forEach(provCat => {
							if (provCat.object.id) {
								if (this.allCategories.length) {
									this.allCategories.forEach(item => {
										if (item.node.id === provCat.object.id) {
											categories.push(item.node.name);
										}
									});
								}
							}
						});
						if (categories.length) {
							providerObj.category  = categories[0];
						}
					}
					specialtyProviders.push(providerObj);
				});
				providers = providers.concat(specialtyProviders);
			});
			if (providers.length) {
				let providerLocationIDs = [];
				let providerLocations = [];
				providers = removeDuplicateObjectFromArray(providers, 'id');
				providers.sort((a, b) => {
					let fa = a.firstName.toLowerCase(),
						fb = b.firstName.toLowerCase();

					if (fa < fb) {
						return -1;
					}
					if (fa > fb) {
						return 1;
					}
					return 0;
				});
				providers.forEach(provider => {
					if (provider.location.length > 0) {
						provider.location.forEach(id => {
							if (!providerLocationIDs.includes(id)) {
								providerLocationIDs.push(id);
							}
						});
					}
				});
				newDatum.providers = providers;
				if (providerLocationIDs.length > 0) {
					if (this.allLocations.length) {
						this.allLocations.forEach(location => {
							if (providerLocationIDs.includes(location.node.id)) {
								let locObject = {
									name: location.node.name,
									address: location.node.address1,
									floor: location.node.address2,
									city: location.node.city,
									state: location.node.state,
									zip: location.node.zip,
									phone: location.node.phone,
									uri: location.node.uri,
									fax: null,
									hours: {},
								}
								if (location.node.hoursMon) { locObject.hours.Mo = location.node.hoursMon };
								if (location.node.hoursTue) { locObject.hours.Tu = location.node.hoursTue };
								if (location.node.hoursWed) { locObject.hours.We = location.node.hoursWed };
								if (location.node.hoursThu) { locObject.hours.Th = location.node.hoursThu };
								if (location.node.hoursFri) { locObject.hours.Fr = location.node.hoursFri };
								if (location.node.hoursSat) { locObject.hours.Sa = location.node.hoursSat };
								if (location.node.hoursSun) { locObject.hours.Su = location.node.hoursSun };
								providerLocations.push(locObject);
							}
						})
					}
				}
				if (providerLocations.length > 0) {
					newDatum.locations = providerLocations;
				}
			} else {
				newDatum.providers = [];
			}
		}
		return newDatum;
	}

	render() {
		let providers = this.state.data.providers;
		let locations = this.state.data.locations;
		return (
			<>
			<Layout>
				<Masthead sidebarImage={this.state.data.sidebarImage} sidebar={this.state.data.sidebar} background={this.state.data.background} name={this.state.data.name} description={this.state.data.description} />
				<div style={{marginTop: '50px'}} />
				<Specialties data={this.state.data.specialties} title="Our Specialties" />
				<hr />
				{providers.length > 0 && <ProviderList data={providers} title="Our Providers" />}
				{locations.length > 0 && <LocationList department={this.state.department} data={locations} title={`${this.state.data.name} Locations`} />}
				<PatientEducationController tags={this.state.data.tags} />
				<DepartmentNewsController tags={this.state.data.tags} />
			</Layout>
			<SEO title={`${this.state.data.name} | ${this.props.data.site.siteMetadata.title}`} />
			</>
		)
	}
}

export const query = graphql`
	query ($uri: String) {
	  allPhysiciansDepartment(filter: {uri: {eq: $uri}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        uri
	        name
	        description
	        sidebar
	        backgroundColor
	        sidebarImage
	        tags
	        Specialty {
	          object {
	            id
	            name
	            uri
	          }
	        }
	      }
	    }
	  }
	  allPhysiciansSpecialty(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	      	id
	      	name
	        Provider {
	          object {
	          	id
		      	firstName
		      	lastName
		      	photo
		      	abbreviations
		      	uri
		      	photo
		      	directPhone
		      	Category {
		      	  object {
		      	  	id
		      	  }
		      	}
		      	Specialty {
		      	  object {
		      	  	id
		      	  }
		      	}
		      	Location {
		      	  object {
		      	  	id
		      	  }
		      	}
	          }
	        }
	        Department {
	          object {
	          	id
	          }
	        }
	      }
	    }
	  }
	  allPhysiciansCategory(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        name
	      }
	    }
	  }
	  allLocationsLocation(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	  	edges {
	  	  node {
	  	  	id
	  	  	name
            address1
            address2
            city
            state
            zip
            uri
            permalink
            hoursMon
            hoursTue
            hoursWed
            hoursThu
            hoursFri
            hoursSat
            hoursSun
            hoursNotes
            phone
	  	  }
	  	}
	  }
	  site {
	    siteMetadata {
	      title
	    }
	  }
	}
`